<template>
  <div
    class="modal fade"
    id="modal_upload_school_form"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                Upload School Form Files
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="resetForm()"
            id="close_upload_schoolform_button"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body py-lg-10 px-lg-10">
            <div class="row mb-6">
                <el-upload
                    ref="upload"
                    class="upload-demo"
                    :on-change="addFileData"
                    action="#"
                    :limit="1"
                    :on-exceed="handleExceed"
                    :auto-upload="false"
                >
                    <template #trigger>
                        <el-button type="primary">select file</el-button>
                    </template>
                    <el-input
                        v-model="fileDescription"
                        placeholder="Enter file description"
                        class="col-md-8"
                        style="margin-left:10px;margin-right:10px"/>
                    <el-button class="ml-3" type="success" @click="submitUpload">Upload</el-button>
                    <template #tip>
                        <div class="el-upload__tip" style="color: red">
                            limit 1 file, new file will cover the old file
                        </div>
                    </template>
                </el-upload>
            </div>
          </div>
        </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create App-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";

export default defineComponent({
    name: "student-settings",
    components: {
    },
    props:{
    },
    emits: ['getFiles'],
    setup(props,{emit}) {  
        const upload = ref<any>(null);
        const uploadUrl = ref('');
        const fileDescription = ref('');
        var fd = ref({} as any);

        const handleExceed = (files) => {
            upload.value.clearFiles();
            upload.value.handleStart(files[0]);
        }

        const resetForm = () =>{
          fileDescription.value = '';
          upload.value?.clearFiles();
          fd.value = new window.FormData();
        }

        const submitUpload = () => {
            ApiService.setHeader();
            ApiService.setFormDataHeader();
            ApiService.post("file/upload/school-form/"+ fileDescription.value, fd.value)
                .then(()=>{
                    setTimeout(() =>{
                      let closeBtn = document.getElementById('close_upload_schoolform_button');
                      closeBtn?.click();
                      emit('getFiles');
                    }, 500);
                })
                .catch(({ response }) => {
                    console.log('createInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }

        const addFileData = (file) => {
            fd.value = new window.FormData();
            fd.value.append('file', file.raw, file.name);
            return false;
        }

        return {
            uploadUrl,
            handleExceed,
            submitUpload,
            fileDescription,
            upload,
            addFileData,
            resetForm,
        };
    },
});

function useRef<T>() {
  throw new Error("Function not implemented.");
}
</script>