
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref} from "vue";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import Swal from "sweetalert2";
import UploadSchoolForm from  "@/views/management/modals/UploadSchoolForm.vue"

export class Invoice{
    id : number;
    invoice_date : Date;
    student_id : number;
    memo : string;
}

export default defineComponent({
    name: "school-form",
    components : {
        UploadSchoolForm
    },
    setup() {
        const pageSize = 15;
        const page = reactive({pageValue : 1});
        const files = ref<any>([]);

        const getFiles = async () => {
            await new Promise((resolve, reject) => {      
                ApiService.setHeader();      
                ApiService.get("file/type/school-form")
                    .then(({ data }) => {
                        files.value = data.data;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getFinanceRecords error => '+response.data.errors));
                    });
            });
        }

        const downloadFile = (id,name) => {
            ApiService.setHeader();
            ApiService.get("file/url/"+id)
            .then(({ data }) => {
                var a = document.createElement('a');
                var event = new MouseEvent('click');
                a.download = name;
                a.href = data.url;
                a.dispatchEvent(event);
            })
            .catch((error)=>{
                console.log('getStudentFiles error => '+error);
            })
        }

        const deleteSavedFile = (row) =>{
            ElMessageBox.confirm('Are you sure to delete this record?')
                .then(() => {
                ApiService.setHeader();
                ApiService.delete("file/"+row.id)
                .then(({ data }) => {
                    if(data.statusCode != 200){
                        Swal.fire({
                            text: "Deleting file error",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Please try again",
                            customClass: {
                            confirmButton: "btn btn-error",
                            },
                        });
                    }
                    else{
                        Swal.fire({
                            text: "Deleted successfully",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                        });
                        files.value = files.value.filter(x=> x.id != row.id);
                    }
                })
                .catch((error)=>{
                    console.log('deleteSavedFile error => '+error);
                });
            });
        }

        const search = reactive({searchValue : ''});

        const pagedFiles = computed(()=>{
            return files.value
                .filter(data => (!search 
                            || data.student?.first_name.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.student?.last_name.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.student?.student_id.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase()))
                )
                .sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.create_date!) < Date.parse(b.create_date!))
                        return 1;
                    if (Date.parse(a.create_date!) > Date.parse(b.create_date!))
                        return -1;
                    return 0;
                })
                .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue)
        })

        const setPage = (val) => {
            page.pageValue = val;
        }

        onBeforeMount(async () => {
            getFiles();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs("School Forms", ["Management"]);
        });

        return {
            setPage,
            pageSize,
            page,
            files,
            search,
            moment,
            deleteSavedFile,
            pagedFiles,
            downloadFile,
            getFiles
        };
    },
});
