
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";

export default defineComponent({
    name: "student-settings",
    components: {
    },
    props:{
    },
    emits: ['getFiles'],
    setup(props,{emit}) {  
        const upload = ref<any>(null);
        const uploadUrl = ref('');
        const fileDescription = ref('');
        var fd = ref({} as any);

        const handleExceed = (files) => {
            upload.value.clearFiles();
            upload.value.handleStart(files[0]);
        }

        const resetForm = () =>{
          fileDescription.value = '';
          upload.value?.clearFiles();
          fd.value = new window.FormData();
        }

        const submitUpload = () => {
            ApiService.setHeader();
            ApiService.setFormDataHeader();
            ApiService.post("file/upload/school-form/"+ fileDescription.value, fd.value)
                .then(()=>{
                    setTimeout(() =>{
                      let closeBtn = document.getElementById('close_upload_schoolform_button');
                      closeBtn?.click();
                      emit('getFiles');
                    }, 500);
                })
                .catch(({ response }) => {
                    console.log('createInvoice error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new invoice",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }

        const addFileData = (file) => {
            fd.value = new window.FormData();
            fd.value.append('file', file.raw, file.name);
            return false;
        }

        return {
            uploadUrl,
            handleExceed,
            submitUpload,
            fileDescription,
            upload,
            addFileData,
            resetForm,
        };
    },
});

function useRef<T>() {
  throw new Error("Function not implemented.");
}
